<template>
  <div>
    <b-row>
      <b-col
        md="4"
      >
        <iq-card>
          <template v-slot:body>
            <div class="d-flex d-flex align-items-center justify-content-between mb-1">
              <div>
                <h2>{{ data.total_pending }}</h2>
                <p class="fontsize-sm m-0">
                  Pending Sales Order(s)
                </p>
              </div>
              <div class="rounded-circle iq-card-icon dark-icon-light iq-bg-warning">
                <i class="ri-inbox-fill" />
              </div>
            </div>
            <router-link
              :to="viewMore('Pending')"
              class="d-flex justify-content-end"
            >
              View More
              <i
                class="ri-arrow-right-s-line"
                style="margin-top:1px"
              />
            </router-link>
          </template>
        </iq-card>

      </b-col>
      <b-col md="4">

        <iq-card>
          <template v-slot:body>
            <div class="d-flex d-flex align-items-center justify-content-between mb-1">
              <div>
                <h2>{{ data.total_rejected }}</h2>
                <p class="fontsize-sm m-0">
                  Rejected Sales Order(s)
                </p>
              </div>
              <div class="rounded-circle iq-card-icon dark-icon-light iq-bg-danger">
                <i class="ri-file-excel-line" />
              </div>
            </div><router-link
              :to="viewMore('Rejected')"
              class="d-flex justify-content-end"
            >
              View More
              <i
                class="ri-arrow-right-s-line"
                style="margin-top:1px"
              />
            </router-link>
          </template>
        </iq-card>
      </b-col>
      <b-col md="4">
        <iq-card>
          <template v-slot:body>
            <div class="d-flex d-flex align-items-center justify-content-between mb-1">
              <div>
                <h2>{{ data.total_delivered }}</h2>
                <p class="fontsize-sm m-0">
                  Sales Order Total Delivered
                </p>
              </div>
              <div class="rounded-circle iq-card-icon dark-icon-light iq-bg-success">
                <i class="ri-truck-line" />
              </div>
            </div>
            <router-link
              :to="viewMore('Delivered')"
              class="d-flex justify-content-end"
            >
              View More
              <i
                class="ri-arrow-right-s-line"
                style="margin-top:1px"
              />
            </router-link>
          </template>
        </iq-card>

      </b-col>

    </b-row>

    <b-row class="mt-3">
      <b-col
        v-if="(user ? user.role : '') !== 'logistics'"
        md="4"
      >
        <iq-card>
          <template v-slot:body>
            <div class="d-flex d-flex align-items-center justify-content-between mb-1">
              <div>
                <h2>{{ data.total_close }}</h2>
                <p class="fontsize-sm m-0">
                  Closed Sales Order(s)
                </p>
              </div>
              <div class="rounded-circle iq-card-icon dark-icon-light iq-bg-primary">
                <i class="ri-shut-down-line" />
              </div>
            </div>
            <router-link
              :to="viewMore('close_so')"
              class="d-flex justify-content-end"
            >
              View More
              <i
                class="ri-arrow-right-s-line"
                style="margin-top:1px"
              />
            </router-link>
          </template>
        </iq-card>

      </b-col>
      <b-col
        v-if="(user ? user.role : '') !== 'logistics' && (user ? user.role : '') !== 'warehouse'"
        md="4"
      >
        <iq-card>
          <template v-slot:body>
            <div
              class="d-flex d-flex align-items-center justify-content-between mb-1"
              style="padding-bottom:26.2px"
            >
              <div>
                <h2>{{ data.total_sales }}</h2>
                <p class="fontsize-sm m-0">
                  Total Sales
                </p>
              </div>
              <div class="rounded-circle iq-card-icon dark-icon-light iq-bg-success">
                <i class=" ri-money-dollar-box-fill" />
              </div>
            </div>
          </template>
        </iq-card>

      </b-col>
    </b-row>

    <b-card-group deck />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Dashboard',

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },

  methods: {
    viewMore (status) {
      return {
        path: `/${this.user?.role}/sales-order-tracker/${status}`
      }
    }
  }

}
</script>
